<template>
    <CSidebar position="fixed" class="sidebar_nav">
        <CSidebarBrand class="mb-3" @click="this.$router.push('/')">
            <CRow
                class="col-12 clickable align-content-center justify-content-center align-items-center">
                <img
                    alt="Coherent logo"
                    class="sidebar-brand-full"
                    style="height: 100px; width: 100px"
                    src="/images/elephant-circle.svg" />
            </CRow>
        </CSidebarBrand>

        <CSidebarNav class="sidebar_links">
            <template v-for="item in navigationItems">
                <AppSidebarItem :item="item" v-if="!item.hidden" />
            </template>
        </CSidebarNav>
    </CSidebar>
</template>

<script>
import AppSidebarItem from './AppSidebarItem.vue';
import useUserStore from '../../stores/userStore';

export default {
    setup() {
        const userStore = useUserStore();
        return { userStore };
    },
    name: 'AppSidebar',
    components: {
        AppSidebarItem,
    },
    data() {
        return {
            merchant: null,
            navigationItems: [
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Onboarding',
                    to: '/onboarding/start',
                    faIcon: 'fa-clipboard-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Partner Center',
                    to: '/onboarding/new',
                    icon: 'till',
                    faIcon: 'fa-rocket',
                },
                {
                    component: 'CNavItem',
                    name: 'Till',
                    to: '/dashboard/till',
                    faIcon: 'fa-cash-register',
                },
                {
                    component: 'CNavItem',
                    name: 'Direct Debits',
                    to: '/dashboard/direct-debits',
                    faIcon: 'fa-university',
                },
                {
                    component: 'CNavItem',
                    name: 'Transactions',
                    to: '/dashboard/transactions',
                    faIcon: 'fa-rectangle-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Appointments',
                    to: '/dashboard/appointments',
                    faIcon: 'fa-calendar-days',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Ellie',
                    to: '/ellie',
                    faIcon: 'fa-rectangle-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Retention',
                    items: [
                        {
                            component: 'CNavItem',
                            name: 'Rebook',
                            toUrl: 'https://www.notion.so/coherenthealthcare/147b91f00ed380eeb3dde7baeb33a136?v=147b91f00ed380a28fb6000c311bbb12',
                            faIcon: 'fa-arrows-rotate',
                        },
                        {
                            component: 'CNavItem',
                            name: 'New Lead',
                            toUrl: 'https://www.notion.so/coherenthealthcare/147b91f00ed380d7b511e243733d6486?v=147b91f00ed38067bdae000c7cbb0e87',
                            faIcon: 'fa-thumbs-up',
                        },
                        {
                            component: 'CNavItem',
                            name: 'Off Track',
                            toUrl: 'https://www.notion.so/coherenthealthcare/142b91f00ed380ac9b95d49eca3026ae',
                            faIcon: 'fa-calendar-xmark',
                        },
                        {
                            component: 'CNavItem',
                            name: 'Scripts',
                            toUrl: 'https://www.notion.so/coherenthealthcare/Scrips-templates-149b91f00ed38016b511cef5b7cf634b',
                            faIcon: 'fa-scroll',
                        },
                    ],
                    faIcon: 'fa-arrow-trend-up',
                },
                {
                    component: 'CNavItem',
                    name: 'Summary',
                    to: '/dashboard/summary',
                    faIcon: 'fa-pie-chart',
                },
                {
                    component: 'CNavItem',
                    name: 'Support',
                    to: '/dashboard/support',
                    faIcon: 'fa-question',
                },
            ],
        };
    },
    computed: {},
    async mounted() {
        const user = await this.userStore.getUserInfo;
        let userRole = user?.role || 'regular';
        let merchantData = user.merchant;

        let nestor = 'dd199a06-4cd6-41a9-a0c8-d2a80c96560b'
        let namma = '7e77af41-f041-47c2-b289-c75298be3636'
        let advanced_chiro = '7d1702ee-9355-4e02-94fc-7b9901c05ed4'
        const rozina = 'ec49046f-9d51-45d5-9537-dd6568116054'
        // Filtering out appointments from the navigation list for all merchants other than the following.

        let ellieListShowForMerchants = [
            '163a70f5-a8ac-4c5c-8d27-f86fe509d85d', // NH Test
            '9d0a2832-1d5b-4792-9066-965af320b5c2', // local
            'aecbac00-9f82-4cc1-865f-b4de12f6ba2e', // NH
            'dd199a06-4cd6-41a9-a0c8-d2a80c96560b', // Nestor
            rozina
        ];
        let ellieShowForMerchants = [
            // 'aecbac00-9f82-4cc1-865f-b4de12f6ba2e', // NH
            '618536d7-1bf2-4a58-85db-3ba419e9099a', // Coherent Test
            'eed9766b-ab1d-476e-a460-44798e8723be', // Coherent Prod
        ];
        let appointmentsShowForMerchants = [
            'aecbac00-9f82-4cc1-865f-b4de12f6ba2e', // NH
            '618536d7-1bf2-4a58-85db-3ba419e9099a', // Coherent Test
            'eed9766b-ab1d-476e-a460-44798e8723be', // Coherent Prod
            'fea44f75-31a4-4b78-8bbf-49a6d4bbea8f', // Dr Tanja Phillips
            '3045ab64-4ddb-4c9a-ba7c-c81e60d3e8f2', // The Row Dental
            rozina
        ];

        if (merchantData) {
            this.merchant = merchantData;

            if (!merchantData?.onboarded) {
                const index = this.navigationItems.findIndex(
                    (it) => it.name === 'Onboarding',
                );
                this.navigationItems[index].hidden = false;
            }

            // ? Add  these for Advanced chiro
            else if(advanced_chiro === merchantData.uuid) {
                this.navigationItems = [
                    ...this.navigationItems.slice(0, 7),
                    {
                        component: 'CNavItem',
                        hidden: false,
                        name: 'Exit Tracker',
                        toUrl: 'https://coherenthealthcare.notion.site/1a5b91f00ed3810ba039fabe156ac18d?pvs=105',
                        faIcon: 'fa-arrow-trend-up',
                    },
                    {
                        component: 'CNavItem',
                        hidden: false,
                        name: 'Ellie 0.1',
                        toUrl: 'https://www.notion.so/coherenthealthcare/1a5b91f00ed3803a95d7f8b94de9872e?v=1a5b91f00ed38133b127000c791109c4',
                        faIcon: 'fa-rectangle-list',
                    },
                    ...this.navigationItems.slice(7)
                ]
            }
            // ? Add  these for NAMMA
            else if(namma === merchantData.uuid) {

                this.navigationItems = [
                    ...this.navigationItems.slice(0, 7),
                    {
                        component: 'CNavItem',
                        hidden: false,
                        name: 'Exit Tracker',
                        toUrl: 'https://coherenthealthcare.notion.site/1adb91f00ed380bc94c8de6c9c31a993',
                        faIcon: 'fa-arrow-trend-up',
                    },
                    {
                        component: 'CNavItem',
                        hidden: false,
                        name: 'Ellie 0.1',
                        toUrl: 'https://www.notion.so/coherenthealthcare/160b91f00ed380b88e75e95a5be5892a?v=195b91f00ed3806f9f8c000c4a6622f2',
                        faIcon: 'fa-rectangle-list',
                    },
                    ...this.navigationItems.slice(7)
                ]
            }
            if (userRole === 'partner') {
                const index = this.navigationItems.findIndex(
                    (it) => it.name === 'Partner Center',
                );
                this.navigationItems[index].hidden = false;
            }

            if (appointmentsShowForMerchants.includes(merchantData.uuid)) {
                const appointmentsIndex = this.navigationItems.findIndex(
                    (it) => it.name === 'Appointments',
                );
                this.navigationItems[appointmentsIndex].hidden = false;
            }

            if (ellieListShowForMerchants.includes(merchantData.uuid)) {
                const ellieListIndex = this.navigationItems.findIndex(
                    (it) => it.name === 'Ellie',
                );
                this.navigationItems[ellieListIndex].hidden = false;
            }

            if (ellieShowForMerchants.includes(merchantData.uuid)) {
                const ellieIndex = this.navigationItems.findIndex(
                    (it) => it.name === 'Retention',
                );
                this.navigationItems[ellieIndex].hidden = false;
            }
        }
    },
};
</script>

<style lang="scss">
.sidebar_nav {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar_links {
    font-family: Inter !important;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-items: center;
    .nav_item {
        display: flex;
        align-items: center;
        justify-content: start;
        color: #747a80;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0;
        width: 100%;

        .nav_item_icon {
            // margin-right: 10px;
            width: 25%;
            filter: invert(50%);
        }
    }
    .nav_item_active {
        color: black !important;

        .nav_item_icon {
            filter: invert(0%);
        }
    }

    .nav-group {
        .nav-group-toggle {
            display: flex;
            align-items: start;
            justify-content: start;
            color: #747a80;
            padding: 1rem 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0;
            width: 100%;

            .nav_item_icon {
                // margin-right: 10px;
                width: 30%;
                filter: invert(50%);
            }
        }
    }

    :hover {
        color: black !important;

        .nav_item_icon {
            filter: invert(0%);
        }
    }
}
</style>
