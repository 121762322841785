<template>
    <CRow class="full-screen-wrapper justify-content-center">
        <CCol class="d-flex flex-column min-vh-100 bg-light" :md="12" :lg="10" :xl="8" :xxl="6">
            <AppHeader :signOutButton="true" />
            <div class="body d-flex flex-column flex-grow-1 px-3">
                <router-view />
            </div>
        </CCol>
    </CRow>
</template>
<script>
import {CContainer} from '@coreui/vue'
import AppFooter from '../components/AppFooter.vue'
import AppHeader from '../components/AppHeader.vue'
import transactionsPopup from "@/components/TransactionsPopup.vue";
import useUserStore from '../stores/userStore';

export default {
    name: 'FullScreenLayout',
    components: {
        transactionsPopup,
        AppFooter,
        AppHeader,
        CContainer,
    },
    setup() {
        const userStore = useUserStore();
        userStore.getUserInfo;
        return { userStore };
    }
}
</script>

<style scoped>
.full-screen-wrapper {
    background-color: #e0e5e5;
}
</style>
