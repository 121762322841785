import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

//PrimeVue CSS files required
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/aura-light-green/theme.css';
import ToastService from 'primevue/toastservice';

import CoreuiVue from '@coreui/vue';
import PrimeVue from 'primevue/config';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';
import axios from 'axios';
import Loader from '@/components/Loader.vue';
import { SERVICE_URL } from '@/serviceResolver';
import Notifications from '@kyvg/vue3-notification';
import { library as fontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FirebasePlugin from './plugins/firebase';
import {
    faAt,
    faArrowDown,
    faArrowTrendUp,
    faArrowsRotate,
    faArrowUp,
    faBackspace,
    faBars,
    faBroom,
    faBuildingColumns,
    faCalculator,
    faCalendarAlt,
    faCalendarXmark,
    faCaretDown,
    faCashRegister,
    faCheck,
    faCircleCheck,
    faCircleInfo,
    faCircleNotch,
    faCircleQuestion,
    faCircleXmark,
    faClipboardList,
    faClock,
    faCreditCard,
    faCreditCardAlt,
    faDesktop,
    faEraser,
    faEye,
    faEyeSlash,
    faFileExport,
    faFilter,
    faLink,
    faList,
    faLocationDot,
    faPause,
    faPaperPlane,
    faPen,
    faPenToSquare,
    faPieChart,
    faPlay,
    faPlus,
    faPrint,
    faRocket,
    faQuestion,
    faReceipt,
    faRectangleList,
    faRepeat,
    faRotate,
    faScroll,
    faSms,
    faSort,
    faSquareMinus,
    faSquarePlus,
    faThumbsUp,
    faTrashCan,
    faUndo,
    faUniversity,
    faUpRightFromSquare,
    faUser,
    faXmark,
    faUserSlash,
    faHourglassStart,
    faComments,
    faTableCellsLarge,
    faForward,
    faStopwatch,
    faCircle,
    faCaretRight,
    faEnvelope,
    faPhone,
    faSpinner,
    faUserCheck,
    faCalendarCheck,
    faWallet,
    faTriangleExclamation,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

import { faComments as faCommentsOutline } from '@fortawesome/free-regular-svg-icons';


import {faWhatsapp}  from '@fortawesome/free-brands-svg-icons';
import VueApexCharts from 'vue3-apexcharts';

//PrimeVue components
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import InputSwitch from 'primevue/inputswitch';
import { createPinia } from 'pinia';
import Tooltip from 'primevue/tooltip';
import Skeleton from 'primevue/skeleton';

fontAwesomeLibrary.add(faAt);
fontAwesomeLibrary.add(faArrowDown);
fontAwesomeLibrary.add(faArrowsRotate);
fontAwesomeLibrary.add(faArrowUp);
fontAwesomeLibrary.add(faBackspace);
fontAwesomeLibrary.add(faBars);
fontAwesomeLibrary.add(faBroom);
fontAwesomeLibrary.add(faBuildingColumns);
fontAwesomeLibrary.add(faCalculator);
fontAwesomeLibrary.add(faCalendarAlt);
fontAwesomeLibrary.add(faCalendarCheck);
fontAwesomeLibrary.add(faCalendarXmark);
fontAwesomeLibrary.add(faCaretDown);
fontAwesomeLibrary.add(faCaretRight);
fontAwesomeLibrary.add(faCashRegister);
fontAwesomeLibrary.add(faCheck);
fontAwesomeLibrary.add(faCircle);
fontAwesomeLibrary.add(faCircleCheck);
fontAwesomeLibrary.add(faCircleInfo);
fontAwesomeLibrary.add(faCircleNotch);
fontAwesomeLibrary.add(faCircleQuestion);
fontAwesomeLibrary.add(faCircleXmark);
fontAwesomeLibrary.add(faClipboardList);
fontAwesomeLibrary.add(faClock);
fontAwesomeLibrary.add(faComments);
fontAwesomeLibrary.add(faCommentsOutline);
fontAwesomeLibrary.add(faCreditCard);
fontAwesomeLibrary.add(faCreditCardAlt);
fontAwesomeLibrary.add(faDesktop);
fontAwesomeLibrary.add(faEnvelope);
fontAwesomeLibrary.add(faEraser);
fontAwesomeLibrary.add(faEye);
fontAwesomeLibrary.add(faEyeSlash);
fontAwesomeLibrary.add(faForward);
fontAwesomeLibrary.add(faFileExport);
fontAwesomeLibrary.add(faFilter);
fontAwesomeLibrary.add(faHourglassStart);
fontAwesomeLibrary.add(faList);
fontAwesomeLibrary.add(faLink);
fontAwesomeLibrary.add(faLocationDot);
fontAwesomeLibrary.add(faMagnifyingGlass);
fontAwesomeLibrary.add(faPaperPlane);
fontAwesomeLibrary.add(faPause);
fontAwesomeLibrary.add(faPen);
fontAwesomeLibrary.add(faPenToSquare);
fontAwesomeLibrary.add(faPieChart);
fontAwesomeLibrary.add(faPhone);
fontAwesomeLibrary.add(faPlay);
fontAwesomeLibrary.add(faPlus);
fontAwesomeLibrary.add(faPrint);
fontAwesomeLibrary.add(faQuestion);
fontAwesomeLibrary.add(faReceipt);
fontAwesomeLibrary.add(faRectangleList);
fontAwesomeLibrary.add(faRepeat);
fontAwesomeLibrary.add(faRocket);
fontAwesomeLibrary.add(faRotate);
fontAwesomeLibrary.add(faScroll);
fontAwesomeLibrary.add(faSort);
fontAwesomeLibrary.add(faSms);
fontAwesomeLibrary.add(faSpinner);
fontAwesomeLibrary.add(faStopwatch);
fontAwesomeLibrary.add(faSquareMinus);
fontAwesomeLibrary.add(faSquarePlus);
fontAwesomeLibrary.add(faTableCellsLarge);
fontAwesomeLibrary.add(faThumbsUp);
fontAwesomeLibrary.add(faTrashCan);
fontAwesomeLibrary.add(faTriangleExclamation);
fontAwesomeLibrary.add(faArrowTrendUp);
fontAwesomeLibrary.add(faUndo);
fontAwesomeLibrary.add(faUser);
fontAwesomeLibrary.add(faUserCheck);
fontAwesomeLibrary.add(faUserSlash);
fontAwesomeLibrary.add(faUniversity);
fontAwesomeLibrary.add(faUpRightFromSquare);
fontAwesomeLibrary.add(faWhatsapp);
fontAwesomeLibrary.add(faWallet)
fontAwesomeLibrary.add(faXmark);

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(CoreuiVue);
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(Notifications);
app.use(FirebasePlugin);
app.use(ToastService);
app.use(VueApexCharts);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('Loader', Loader);
app.component('font-awesome-icon', FontAwesomeIcon);

// PrimeVue components
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Button', Button);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('SelectButton', SelectButton);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Sidebar', Sidebar);
app.component('ScrollPanel', ScrollPanel);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Calendar', Calendar);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('OverlayPanel', OverlayPanel);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Skeleton',Skeleton)
app.component('Password', Password);
app.directive('Tooltip', Tooltip);
app.mount('#app');

async function initializeApp() {
    try {
        const token = localStorage.getItem('authToken');
        if (token) {
            axios.defaults.headers.common['access-token'] = token;
            await axios.get(`${SERVICE_URL}/init`);
        }
    } catch (error) {
        axios.defaults.headers.common['access-token'] = null;
        localStorage.removeItem('authToken');
        router.push({ name: 'Login' });
    }
}

initializeApp();
