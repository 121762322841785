import currency from 'currency.js';
import * as moment from 'moment';
import { MINIMUM_AMOUNT_IN_POUNDS } from '@/constants';

export function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.$notify({ type: 'success', text: 'Link Copied' });
}

export function focusInputField(field) {
    this.$nextTick(() => {
        this.$refs[field].$el.nextElementSibling.focus();
    });
}

export function isLocalStorageAvailable() {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export function createAmountValidator(yup, currency) {
    return yup
        .string()
        .test(
            'parseAmount',
            renderMinimumAmountMessage(currency),
            (value, context) => {
                const valueOnly = value
                    .replace('£', '')
                    .replace('€', '')
                    .replace('$', '');

                if (valueOnly.replace('.', '').length > 0) {
                    const parsedValue = parseFloat(valueOnly, 10);
                    return parsedValue >= MINIMUM_AMOUNT_IN_POUNDS;
                }
                return false;
            },
        );
}

export function renderCurrency(amount, currency, locales = 'en-GB') {
    const formatter = Intl.NumberFormat(
        locales,
        currency
            ? {
                  style: 'currency',
                  currency: currency,
              }
            : {},
    );

    return formatter.format(amount);
}

export function renderCurrencyFromCents(amountInCents, currencyCode) {
    return currency(amountInCents, { fromCents: true }).format({
        symbol: renderSymbol(currencyCode),
    });
}

export function renderMinimumAmountMessage(currency) {
    return `Minimum ${
        currency ? renderSymbol(currency) : ''
    }${MINIMUM_AMOUNT_IN_POUNDS}`;
}

export function renderSymbol(currency) {
    switch (currency) {
        case 'GBP':
            return '£';
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            return '';
    }
}

export function renderPaymentMethodIcon(paymentMethod) {
    if (isCardPayment(paymentMethod)) {
        return 'fa-credit-card';
    } else if (paymentMethod === 'directdebit_GB') {
        return 'fa-repeat';
    } else if (paymentMethod === 'paybybank') {
        return 'fa-building-columns';
    } else {
        return 'fa-circle-question';
    }
}

export function renderPaymentMethodName(paymentMethod) {
    if (isCardPayment(paymentMethod)) {
        return 'Card';
    } else if (paymentMethod === 'directdebit_GB') {
        return 'DD';
    } else if (paymentMethod === 'paybybank') {
        return 'Bank';
    } else if (paymentMethod === 'null') {
        return 'None';
    } else {
        return 'Unknown';
    }
}

function isCardPayment(paymentMethod) {
    return (
        paymentMethod &&
        (paymentMethod === 'card' ||
            paymentMethod.startsWith('mc') ||
            paymentMethod.startsWith('visa') ||
            paymentMethod.startsWith('amex'))
    );
}

export function renderStatus(status) {
    return renderEnumValue(status);
}

export function renderEnumValue(value) {
    return (
        value.substring(0, 1).toUpperCase() +
        value.substring(1).toLowerCase().replaceAll('_', ' ')
    );
}

export function renderTime(isoString) {
    return moment(isoString).format('HH:mm');
}

export function renderDateTime(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}/${month} ${hours}:${minutes}`;
    return formattedDate;
}

export function renderDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    const formattedDate = `${day}/${month}`;
    return formattedDate;
}

export function renderDateToString(dateObject) {
    if (!dateObject) return '';
    return dateObject.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
}

export function renderFullDate(dateObject) {
    const date = new Date(dateObject);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    return date.toLocaleString('en-GB', options);
}

export function getMostRecentActionType(actionHistory) {
    if (!actionHistory || actionHistory.length === 0) return null;
    const data = actionHistory.filter((action) => action.type !== 'assign');
    data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return data[0]?.type || 'none';
}

export function getActionIcon(type) {
    switch (type) {
        case 'exclude':
            return ['fa-solid', 'fa-user-slash'];
        case 'contact':
            return ['fa-solid', 'fa-comments'];
        case 'snooze':
            return ['fa-solid', 'fa-stopwatch'];
        case 'assign':
            return ['fa-solid', 'fa-user'];
        case 'appointment':
            return ['fa-solid', 'fa-user'];
        case 'ready':
            return ['fa-solid', 'fa-play'];
        case 'resolve':
            return ['fa-solid', 'fa-circle-check'];
        default:
            return ['fa-solid', 'fa-user'];
    }
}
export function getActionColor(type) {
    switch (type) {
        case 'appointment':
            return 'black';
        case 'exclude':
            return 'red';
        case 'snooze':
            return 'grey';
        case 'contact':
            return '#0683a5';
        case 'assign':
            return '#0683a5';
        case 'ready':
            return 'green';
        case 'resolve':
            return 'green';
        default:
            return 'black';
    }
}

export function generateTimeline(card) {
    let timeline = [];
    card.actionHistory?.forEach((action, index) => {
        if (!card.customer_id) {
            timeline.push({
                status: action.type,
                date: action.timestamp,
                description:
                    action.type === 'assign'
                        ? `${action.assignee}  ${
                              action.notes ? ` - ${action.notes}` : ''
                          }`
                        : action.notes || '',
                effectiveUntil: action.effectiveUntil,
            });
        } else {
            if ((index === 0 && action.type === 'ready') || action.type === 'assign') return;
   
            timeline.push({
                status: action.type,
                date: action.timestamp,
                description: action.notes || '',
                effectiveUntil: action.effectiveUntil,
            });
        }
    });

    card.appointments?.forEach((appointment) => {
        timeline.push({
            status: 'appointment',
            date: appointment.startTime,
            clinicianName: appointment.clinicianName,
            description: appointment.description,
            price: appointment.price,
        });
    });

    return timeline.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });
}

export function findAssigned(card) {
    if (!card.actionHistory.length) return 'Unassigned';
    let assignedPerson = '';
    card.actionHistory
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .forEach((action) => {
            if (action.type === 'assign') {
                assignedPerson = action.assignee || 'someone';
            }
        });
    return assignedPerson || 'Unassigned';
}

export function recentReason(card, selectedList) {
    if (!card?.actionHistory?.length) return '';

    const actionHistory = [...card.actionHistory];
    const recentReadyAction = actionHistory
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .find((action) => action.type === 'ready');

    return recentReadyAction?.reason || (card.customer_id ? selectedList.query : '');
}
